import { Swiper, Navigation, Pagination, Autoplay, EffectFade } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

export default () => {
    let swiper = new Swiper(".gallery-modal-slider", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // componente de banner padrão
    let bannerDefault = new Swiper(".banner__default", {
        watchOverflow: true,
        navigation: {
            nextEl: ".banner-button-next",
            prevEl: ".banner-button-prev",
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let phrasesSlider = new Swiper(".phrases__slider", {
        watchOverflow: true,
        autoplay: {
            delay: 8000,
        },
        navigation: {
            nextEl: ".phrases-button-next",
            prevEl: ".phrases-button-prev",
        },
    });

    let bannerHome = new Swiper(".banner__home", {
        watchOverflow: true,
        speed: 2000,
        effect: "fade",
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".home-pagination",
            type: "bullets",
            clickable: true,
        },
        on: {
            init: function () {
                const firstSlide = this.slides[this.activeIndex];
                firstSlide.querySelector("img").classList.add("zoom-in");
            },
            slideChange: function () {
                const previousSlide = this.slides[this.previousIndex];
                previousSlide.querySelector("img").classList.remove("zoom-in");
            
                const currentSlide = this.slides[this.activeIndex];
                currentSlide.querySelector("img").classList.add("zoom-in");
            },
        },
    });
};
