export default function inputFile() {
    if (document.querySelector(".curriculum--field")) {
        let file = document.querySelector("#curriculum");
        let label = document.querySelector(".field__curriculum label");
        let message = document.querySelector(".curriculum--field p");

        file.addEventListener("change", ({ target, }) => {
            if (target.files[0].type !== "application/pdf") {
                message.style.color = "red";
                file.value = "";
                return;
            }

            label.textContent = target.files[0].name;
            message.style.color = "#000";
        });
    }
}
